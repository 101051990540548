import React from 'react';
import { Grid, Header, Icon, Modal, Segment, Table } from 'semantic-ui-react';

const ReleaseNoteSection = ({color, tickets, title}) => (
  <Grid.Row columns={16}>
    <Grid.Column width={16}>
      <Header>{title}</Header>
      <Table striped color={color || "green"}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Issue Key</Table.HeaderCell>
            <Table.HeaderCell>Summary</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {tickets && tickets.length > 0 ? tickets.map((ticket, index) => (
            <Table.Row key={index}>
              <Table.Cell>{ticket.key}</Table.Cell>
              <Table.Cell>{ticket.summary}{ticket.workaround &&
                <>
                  <br />
                  <Segment>
                    <Header color="orange" as="h3">Workaround:</Header>
                    {ticket.workaround}
                  </Segment>
                </>}
              </Table.Cell>
            </Table.Row>
          )) : (
            <Table.Row>
              <Table.Cell colSpan={2} textAlign="center">
                <Header as="h3">None</Header>
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    </Grid.Column>
  </Grid.Row>
);

const ReleaseNoteModal = ({item}) => {
  const [open, setOpen] = React.useState(false);

  const { release_notes } = item;

  if(!release_notes) {
    return null;
  }

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={<Icon name="sticky note" />}
    >
      <Modal.Header>Release Notes ({item.version})</Modal.Header>
      <Modal.Content>
        <Grid stretched>
        <ReleaseNoteSection tickets={release_notes.bugs} title="Fixed Bugs" />
        <ReleaseNoteSection tickets={release_notes.features} title="New Features" />
        <ReleaseNoteSection tickets={release_notes.known_issues} title="Known Issues" color="red" />
        </Grid>
      </Modal.Content>
    </Modal>
  )
}

export default ReleaseNoteModal;
