import React from 'react';
import { Switch, BrowserRouter as Router, Route } from 'react-router-dom';
import Layout from './Layout';
import Releases from './pages/Releases';

function App() {
  return (
    <Router>
      <Layout>
        <Switch>
          <Route exact path="/releases" component={Releases} />
        </Switch>
      </Layout>
    </Router>
  );
}

export default App;
