import React, { Component } from 'react';
import { Header, Icon, Menu, Segment, Table } from 'semantic-ui-react';
import KodiakCloudApi from '../api/KodiakCloudApi';
import moment from 'moment';
import ReleaseNoteModal from '../components/ReleaseNoteModal';

const CLIENT_RELEASE_STREAMS = [
  "busxpert-main"
];

const FIRMWARE_RELEASE_STREAMS = [
  "kodiak-fw-main"
];

//href={item.download_url} download

const ReleaseTable = ({releases, title}) => (
    <Segment>
      <Header>{title}</Header>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Version</Table.HeaderCell>
            <Table.HeaderCell>Release Date</Table.HeaderCell>
            <Table.HeaderCell>Link</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {releases && releases.map((item) => (<Table.Row key={item.id}>
            <Table.Cell>
              {item.version}
            </Table.Cell>
            <Table.Cell>
              {moment(item.release_date).format("YYYY-MM-DD")}
            </Table.Cell>
            <Table.Cell>
              <Menu text color="blue" borderless compact >
                <Menu.Item position="left" target="_blank" download href={item.download_url}>
                  <Icon name="download" />
                </Menu.Item>
                <Menu.Item position="left" target="_blank" as='a'>
                  <ReleaseNoteModal item={item} />
                </Menu.Item>
              </Menu>
            </Table.Cell>
          </Table.Row>))}
        </Table.Body>
      </Table>
      </Segment>
  );

export default class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      releases: {}
    }
  }

  componentDidMount() {
    CLIENT_RELEASE_STREAMS.forEach((rel) => this.fetchReleases(rel));
    FIRMWARE_RELEASE_STREAMS.forEach((rel) => this.fetchReleases(rel));
  }

  fetchReleases = async (stream) => {
    const resp = await KodiakCloudApi.get(`/streams/${stream}/releases`);
    if(resp.status === 200) {
      this.setState({
        releases: {
          ...this.state.releases,
          [stream]: resp.data.releases
        }
      })
    }
  }

  render() {
    const firmware_releases = this.state.releases["kodiak-fw-main"];
    const client_releases = this.state.releases["busxpert-main"];

    return (
      <>
        <ReleaseTable
          releases={firmware_releases}
          title="Kodiak Firmware Releases"
        />
        <ReleaseTable
          releases={client_releases}
          title="Busxpert Client Releases"
        />
      </>
    )
  }
};
