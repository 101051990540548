import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import {
    Container,
    Divider,
    Icon,
    Menu,
    Segment,
    Grid,
    Sidebar,
    Image,
    Header
} from 'semantic-ui-react';
import _ from 'lodash';
import { createMedia } from '@artsy/fresnel'

/* Import the logo PNG files from our media directory */
import logo from './media/SerialTek_logo_simplified_PNG.png';
import fullLogo from './media/SerialTek_Master_Logo_PNG.png';

const AppMedia = createMedia({
    breakpoints: {
        mobile: 320,
        tablet: 768,
        computer: 992,
        largeScreen: 1200,
        widescreen: 1920
    }
});
const mediaStyles = AppMedia.createMediaStyle();
const { Media, MediaContextProvider } = AppMedia;

/**
 * Renders a mobile version of the navigation bar, which collapses the top menu
 * bar and provides a sidebar (activated with a button) with the navigation
 * instead. The main content is dimmed while the sidebar is active, to give it
 * more distinction
 */
const MobileNavBar = ({
    children,
    leftGroup,
    centerGroup,
    rightGroup,
    onPusherClick,
    onToggle,
    visible
}) => (
    <Sidebar.Pushable>
        <Sidebar
            as={Menu}
            animation="overlay"
            icon="labeled"
            color="blue"
            vertical
            visible={visible}
        >
            {leftGroup.concat(centerGroup).map((item) => (
                <Menu.Item {...item} />
            ))}
        </Sidebar>
        <Sidebar.Pusher
            dimmed={visible}
            onClick={onPusherClick}
            style={{ minHeight: '100vh' }}
        >
            <Menu color="blue" fixed="top">
                <Menu.Item as={Link} to="/">
                    <Image size="tiny" src={logo} />
                </Menu.Item>
                <Menu.Item onClick={onToggle}>
                    <Icon name="sidebar" />
                </Menu.Item>
                <Menu.Menu position="right">
                    {_.map(rightGroup, (item) => (
                        <Menu.Item {...item} />
                    ))}
                </Menu.Menu>
            </Menu>
            {children}
        </Sidebar.Pusher>
    </Sidebar.Pushable>
);

/**
 * Renders a standard version of the navigation bar, which renders our links
 * along the top navigation bar
 */
const StandardNavBar = ({ leftGroup, centerGroup, rightGroup }) => (
    <Menu color="blue" fixed="top">
        <Menu.Item as={Link} to="/">
            <Image size="tiny" src={logo} />
        </Menu.Item>
        {_.map(leftGroup, (item) => (
            <Menu.Item {...item} />
        ))}
        {centerGroup.length > 0 ? (
            <Menu color="blue">
                {_.map(centerGroup, (item) => (
                    <Menu.Item {...item} />
                ))}
            </Menu>
        ) : null}
        <Menu.Menu position="right">
            {_.map(rightGroup, (item) => (
                <Menu.Item {...item} />
            ))}
        </Menu.Menu>
    </Menu>
);

const NavBarChildren = ({ children }) => (
    <Container fluid style={{ marginTop: '5em' }}>
        {children}
    </Container>
);

/**
 * Top level of the navigation bar, which works out whether we are in a mobile
 * form factor or standard web browser form factor, and renders the appropriate
 * navigation bar
 */
class NavBar extends Component {
    state = {
        visible: false
    };

    handlePusher = () => {
        const { visible } = this.state;

        if (visible) {
            this.setState({ visible: false });
        }
    };

    handleToggle = () => this.setState({ visible: !this.state.visible });

    render() {
        const { children, leftGroup, centerGroup, rightGroup } = this.props;
        const { visible } = this.state;

        return (
            <>
                <style>{mediaStyles}</style>
                <MediaContextProvider>
                    <MobileNavBar
                        as={Media}
                        at="computer"
                        leftGroup={leftGroup}
                        centerGroup={centerGroup}
                        rightGroup={rightGroup}
                        onPusherClick={this.handlePusher}
                        onToggle={this.handleToggle}
                        visible={visible}
                    >
                        <NavBarChildren>{children}</NavBarChildren>
                    </MobileNavBar>
                    <StandardNavBar
                        as={Media}
                        at="largeScreen"
                        leftGroup={leftGroup}
                        centerGroup={centerGroup}
                        rightGroup={rightGroup}
                    >
                        <NavBarChildren>{children}</NavBarChildren>
                    </StandardNavBar>
                </MediaContextProvider>
            </>
        );
    }
}

/**
 * The layout used for the entire application. All pages are rendered as
 * children of this layout so that we have a consistent format throughout the
 * site and the boilerplate elements are largely removed
 */
class Layout extends Component {
    render() {
        const { location } = this.props;
        var leftGroup = [
            /* Link to the home page */
            {
                as: Link,
                size: 'tiny',
                name: 'home',
                key: 'home',
                to: '/',
                active: location.pathname === '/'
            }
        ];
        /* Currently the center group is unused, but these can be
            * used to float a separate set of components in the desired location
            * on the navigation bar, such as a login component floated on the
            * right of the bar, which follows the established design trait */
        var centerGroup = [];

        var rightGroup = [
            {
                content: <Header color="blue">BETA</Header>,
                key: 'beta_tag'
            }
        ];

        /* Public routes */
        leftGroup = leftGroup.concat([
            {
                as: Link,
                size: 'tiny',
                name: 'releases',
                key: 'releases',
                to: '/releases',
                active: location.pathname.startsWith('/releases')
            }
        ]);

        return (
            <NavBar
                leftGroup={leftGroup}
                centerGroup={centerGroup}
                rightGroup={rightGroup}
            >
                <Grid>
                    {/* Extra spacer row between the menu and content */}
                    <Grid.Row columns={16}></Grid.Row>
                        <Grid.Row columns={16}>
                            <Grid.Column only="computer" width={1} />
                            <Grid.Column computer={14} tablet={16}>
                                <Segment>
                                    {this.props.children}
                                </Segment>
                                <Divider />
                            </Grid.Column>
                            <Grid.Column only="computer" width={1} />
                        </Grid.Row>
                        <Grid.Row columns={16}>
                            <Grid.Column width={1} />
                            <Grid.Column
                                width={14}
                                verticalAlign="top"
                                textAlign="center"
                            >
                                {process.env.REACT_APP_GIT_SHA || 'DEV'}{' '}
                                <Icon name="copyright outline" /> 2020
                                SerialTek
                            </Grid.Column>
                            <Grid.Column width={1} />
                        </Grid.Row>
                        <Grid.Row columns={16}>
                            <Grid.Column width={1} />
                            <Grid.Column
                                width={14}
                                verticalAlign="top"
                                textAlign="center"
                            >
                                <Image
                                    size="medium"
                                    src={fullLogo}
                                    centered
                                />
                            </Grid.Column>
                            <Grid.Column width={1} />
                        </Grid.Row>
                </Grid>
            </NavBar>
        );
    }
};

/* Make sure that the router properties are injected to our layout, and
 * therefore are passed to all children of the layout */
export default withRouter(Layout);
