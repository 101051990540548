import axios from 'axios';

export var KODIAK_CLOUD_API_ROOT;

/** Base URI of the API. All calls are made relative to this URI */
// Route to the same host as our server, but with the right port number
KODIAK_CLOUD_API_ROOT =
    process.env.REACT_APP_SERVER_URI ||
    window.location.protocol + '//' + window.location.host + "/api";

/**
 * Helper class that encapsulates the Kodiak Cloud REST API
 *
 * This is mainly used to have a common point for authentication, and also helps
 * enforce a common usage pattern in all places where API endpoints are called
 */
class KodiakCloudApiImpl {
    /**
     * Async function that calls a GET on the provided resource URI
     *
     * This function will add basic authentication headers (currently statically
     * defined, but this will come from a login page)
     *
     * @param {*} uri - The relative URI of the resource we want to GET
     * @param {*} timeout - Optional timeout for the request
     */
    get = async (uri, timeout) => {
        var response = null;
        var retry = true;

        /* Use the passed timeout if provided, or 2 seconds otherwise */
        const req_timeout = timeout || 2 * 1000;

        while (retry) {
            try {
                response = await axios.get(KODIAK_CLOUD_API_ROOT + uri, {
                    timeout: req_timeout
                });
            } catch (e) {
                if (e.response) {
                    response = e.response;
                } else {
                    response = {
                        status: 500,
                        data: 'No response'
                    };
                }
            }

            retry = false;
        }
        return response;
    };

    /**
     * Async function that calls a POST on the provided resource URI with the
     * provided data
     *
     * This function will add basic authentication headers (currently statically
     * defined, but this will come from a login page)
     *
     * @param {*} uri - The relative URI of the resource we want to POST
     * @param {*} data - THe object to POST
     */
    post = async (uri, data) => {
        var response = null;
        var retry = true;

        while (retry) {
            try {
                response = await axios.post(KODIAK_CLOUD_API_ROOT + uri, data, {
                    timeout: 1000 * 2
                });
            } catch (e) {
                if (e.response) {
                    response = e.response;
                } else {
                    response = {
                        status: 500,
                        data: 'No response'
                    };
                }
            }

            retry = false;
        }

        return response;
    };

    /**
     * Async function that calls a DELETE on the provided resource URI
     *
     * This function will add basic authentication headers (currently statically
     * defined, but this will come from a login page)
     *
     * @param {*} uri - The relative URI of the resource we want to DELETE
     */
    delete = async (uri) => {
        var response = null;
        var retry = true;

        while (retry) {
            try {
                response = await axios.delete(KODIAK_CLOUD_API_ROOT + uri, {
                    timeout: 1000 * 2
                });
            } catch (e) {
                if (e.response) {
                    response = e.response;
                } else {
                    response = {
                        status: 500,
                        data: 'No response'
                    };
                }
            }

            retry = false;
        }
        return response;
    };

    /**
     * Async function that calls a PATCH on the provided resource URI
     *
     * This function will add basic authentication headers (currently statically
     * defined, but this will come from a login page)
     *
     * @param {*} uri - The relative URI of the resource we want to PATCH
     * @param {*} data - The data to send in the patch request
     */
    patch = async (uri, data) => {
        var response = null;
        var retry = true;

        while (retry) {
            try {
                response = await axios.patch(KODIAK_CLOUD_API_ROOT + uri, data, {
                    timeout: 1000 * 2
                });
            } catch (e) {
                if (e.response) {
                    response = e.response;
                } else {
                    response = {
                        status: 500,
                        data: 'No response'
                    };
                }
            }

            retry = false;
        }
        return response;
    };
}

const KodiakCloudApi = new KodiakCloudApiImpl();

export default KodiakCloudApi;
